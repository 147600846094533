import React from 'react';
import {
    AboutSection,
    ArticlesSection,
    ContactSection,
    HeroSection,
    InterestsSection,
    ExpertiseSection,
    Page,
    Seo,
} from 'gatsby-theme-portfolio-minimal';

export default function IndexPage() {
    return (
        <>
            <link href="https://api.fontshare.com/v2/css?f[]=clash-display@400&display=swap" rel="stylesheet" />
            <Seo title="Birgit Design" />
            <Page useSplashScreenAnimation={false}>
                <HeroSection sectionId="hero" />
                <ArticlesSection sectionId="portfolio" heading="Portfolio" sources={['Blog']} />
                <InterestsSection sectionId="skills" heading="My Skills" />
                <AboutSection sectionId="about" heading="About" />
                <ExpertiseSection sectionId="expertise" heading="My Expertise" />
                <ContactSection sectionId="get-in-touch" heading="Get in Touch" />
            </Page>
        </>
    );
}
